// ==========================================================================
// Budgets List
// ==========================================================================

// 1.Config


// 2.Base

.budgets-list {
	margin-top: 15px;
	border-top:1px solid #eee;

	&__item {
		border-bottom:1px solid #eee;
		position: relative;

		&:after {
			@extend %pseudos;
			height: 15px;
			width: 15px;
			border-radius:50%;
			top: 20px;
			right: 0;
		}


		&--borrador:after {
			background-color:#d8d8d8;			
		}

		&--revision:after {
			background-color:#f5a623;			
		}

		&--finalizado:after {
			background-color:#52b441;
		}

		&--eliminado:after {
			background-color:#b7091e;			
		}
 

	}

	&__link {
		padding: 20px 0;
		display: block;
		color: $black;

		&:hover {
			text-decoration: none;
		}

	}

	&__date {
		font-size: 16px;
		line-height: 24px;
		padding-right: 40px;
		span {
			@extend %font-bold;
		}
		span + span {
			@extend %font-regular;
			opacity: 0.7;
		}
	}

	&__name {
		@extend %font-bold;
		font-size: 16px;
		line-height: 24px;
	}

	&__description {
		@extend %font-regular;
		font-size: 16px;
		line-height: 24px;

	}

	&__description-top {
		@extend %font-regular;
		font-size: 16px;
		line-height: 24px;
		color: $black;
		margin:10px 0 30px;

		a {
			&:hover {
				text-decoration:none;
			}
		}
		
	}

	&__delete {
		position:absolute;
		top: 0;
		right: 0;
		padding: 5px 0 0 0;
	}
}