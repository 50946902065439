.product-item {
	margin-bottom: 20px;
	@extend %clearfix;
	position:relative;
	height: 50px;

	&:after {
		@extend %pseudos;
		height: 1px;
		width: calc(100% + 30px);
		background:#efefef;
		left:-15px;
		bottom:-10px;
	}

	&__color {
		width: 50px;
		height: 50px;
		float:left;
		background: #efefef;
		margin-right:10px;
		display: block;
	}


	&__name {
		float: left;
		color: $black;
		@extend %font-bold;
		font-size: 12px;
	}

	&__price { 
		color: $black;
		@extend %font-bold;
		float:right;
		height:50px;
		line-height: 50px;
	}

	&__actions {
		float:right;
		height:50px;
		line-height: 50px;
		font-size: 18px;
		margin-left: 20px;

		a {
			color:$primary;

			&:hover {
				color:$secondary;
			}


		}
	} 

	&--total {
		background:#efefef;
	}

	&__total {
		color: $black;
		
		float:right;
		height:50px;
		
		line-height: 50px;
		padding-right: 35px;

		span {
			font-size: 18px;
			@extend %font-bold;
		}
	}


}

.product-list {
	padding-bottom:85px;
}


.product-list__button {
	position:fixed;
	bottom:0;
	left:0;
	width:100%;
	padding:0 15px 10px;
	background:#fff;
	text-align: center;

	.btn {
		max-width: 910px;

	}

}


.error-box {
	border:1px solid #CE1548;
	text-align: center;
	
	@include background-opacity(#CE1548,0.2);
	padding:20px;
	margin: 0 0 20px;
	color:#CE1548;
	@extend %font-bold;
}

.ok-box {
	border:1px solid #8bc34a;
	text-align: center;
	
	@include background-opacity(#8bc34a,0.2);
	padding:20px;
	margin: 0 0 20px;
	color:#4caf50;
	@extend %font-bold;
}