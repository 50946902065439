// ==========================================================================
// Form Input
// ==========================================================================

// 1.Config

$inputTextColor: $gray-600;
$inputPaddingV:11px;
$inputPaddingH:3px;
$inputColorFocus: $primary;
$inputTextBorderColor: #000000;
$inputFontSize: 18px;
$inputLabelFontSize: 14px;
$inputTransitionBorder:0.4s;
$inputTransitionLabel:0.3s;


//2. Extends
%labelFocusStyle {
    top: -11px;
    font-size: $inputLabelFontSize;
}

%borderFocusStyles {
    width: 100%;
}

// 3.Base
.form-group {
    padding: 15px 0;
    margin: 0;
    @extend %clearfix;

    &--extra-margin {
      margin-top: 30px;
    }

    &--centered {
      text-align: center;
    }
}

body.desktop  .form-group {
  margin-left: auto;
  margin-right: auto;
  //max-width: 350px;
  //text-align: center;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}


.form-input {
    text-align: left;
    position:relative;

    &__input{
        font-size:$inputFontSize;
        color: $inputTextColor;
        width: 100%;
        box-sizing: border-box;
        border: none;
        border-radius:0;
        padding: $inputPaddingV  29px $inputPaddingV $inputPaddingH;
        border-bottom: 1px solid $inputTextBorderColor;
        background-color: transparent;

        &--important {
          color:$primary;
          @extend %font-bold;
        }

        &:focus {
          outline: none;
        }

        &--textarea {
          //height: 140px;
          height: 50px;
          resize: none;
          display: block;
          transition: $inputTransitionBorder;
          vertical-align: top;
        }

        &.form-control:disabled, &.form-control[readonly] {
          background:#fff;
          border:none;
          cursor: not-allowed;

          &:focus ~ .form-input__label {
            color: $inputTextColor;
          }

           &:focus ~ .form-input__border {
            background-color: $inputColorFocus;

          }

        }
    }

    &__border{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $inputColorFocus;
        transition: $inputTransitionBorder;

    





        &:after {
          pointer-events:none;
          @extend %pseudos;
          font-family:'reveton';
          content: '\e804';
          right:0;
          margin-top: -30px;
          height: 20px;
          width: 20px;
          opacity: 0;
        }
    }

     &__label{
      position: absolute;
      left: $inputPaddingH;
      width: 100%;
      top: 9px;
      color: $inputTextColor;
      transition: $inputTransitionLabel;
      pointer-events:none;
      font-size:$inputFontSize;
      background: #fff;
    }

    &.readonly {
        .form-input__border {
          display:none;
        }

    }


// 4.Focus   

    &__input:focus ~ &__border, &__input.has-content:focus ~ &__border {
        @extend %borderFocusStyles;
        background-color: $inputColorFocus;
    }
    &__input.has-content ~ &__border{
      @extend %borderFocusStyles;
      background-color: $inputTextBorderColor;

      &:after {
        opacity: 1;
        transition: $inputTransitionBorder;
      }
    }

    &__input.has-content ~ &__label{
      @extend %labelFocusStyle;
      color: $inputTextColor;
      
    }

    &__input:focus ~ &__label, &__input.has-content:focus ~ &__label {
        color: $inputColorFocus;
        @extend %labelFocusStyle;
    }


    &__input--textarea:focus,  &__input--textarea.has-content{
      height:140px;
    }


  // 5.Solo lectura

    &__input[readonly].has-content ~ &__border{
      &:after {
        opacity: 0;
      }
    }

  // 6.Form error

    &--error {
        .form-input__input {
          border-bottom:1px solid $red;
        }

        .form-input__border {
          background-color:$red!important;
        }
    }

    .input-message--error {
      position: absolute;
      width: 100%;
      text-align: right;
      font-size: 12px;
      color: $red;
      font-weight: bold;
    }

}


body.desktop .form-input__input--textarea{
    &::-webkit-scrollbar {
     opacity: 0
  }
   
  &::-webkit-scrollbar-track {
      opacity: 0
  }
   
  &::-webkit-scrollbar-thumb {
      opacity: 0;
      background: #fff;
  }
}



  input::-internal-input-suggested {
    opacity: 0!important;
  }

