.new-header {
	left:0;
	padding:15px;
	position:absolute;
	top:0;
	width:100%;
	z-index:100;

	@include media-breakpoint-up(md) {
		//padding:20px 15px;
		padding:30px 15px;
	}

	&__logo {
		height:auto;
		margin:0;
		width:120px;

		@include media-breakpoint-up(md) {
			width:256px;
		}

		img {
			display:block;
			height:auto;
			width:100%;
		}
	}

	.container-fluid {
		align-items:center;
		display:flex;
		flex-wrap:nowrap;
		justify-content:space-between;
		padding:0;

		@include media-breakpoint-up(md) {
			padding:0 15px;
		}
	}

	&__right {
		display:none;

		@include media-breakpoint-up(md) {
			align-items:flex-end;
			flex-wrap:nowrap;
			justify-content:space-around;
		}

		strong {
			color:$black;
			display:none;
			font-size:15px;
			line-height:26px;
			padding-right:20px;

			@include media-breakpoint-up(md) {
				display:block;
			}
		}
	}

	&__menu {
		align-items:center;
		display:flex;
		flex-wrap:nowrap;
		justify-content:space-around;
		margin:0;
		list-style:none;
		padding:0;

		li {
			margin:0 3px;

			@include media-breakpoint-up(md) {
				margin:0 7px;
			}

			&:last-child { margin-right:0; }

			img {
				display:block;
				height:auto;
				width:90px;

				@include media-breakpoint-up(sm) {
					width:100px;
				}

				@include media-breakpoint-up(md) {
					width:162px;
				}
			}
		}
	}
}

.new-header--min {
	background:$white;
	box-shadow:0 2px 4px 0 rgba($black, 0.5);
	padding:17px 15px;
	position:fixed;
	top:-200px;

	&.pinned {
		transition:top .3s ease-in-out;
		top:0;
	}

	&.unpinned {
		top:-200px;
	}

	.new-header__logo {
		margin-left:0;
		width:120px;

		@include media-breakpoint-up(sm) {
			width:211px;
		}
	}
	
	.new-header__right {
		display:flex;
	}
}