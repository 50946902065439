@keyframes scrollDown {
    0% {
        bottom:20px;
    }
    100% {
        bottom:10px;
    }
}

.top-header {   
    margin-bottom:20px;
    min-height:635px;
    overflow:hidden;
    padding-bottom:60px;
    padding-top:60px;
    position:relative;
    width:100%;

    @include media-breakpoint-up(md) { 
    	height:635px;
        margin-bottom:40px;
    }

    &__agroup {
    	align-items:center;
    	display:flex;
    	flex-wrap:wrap;
    	height:auto;
    	margin:0 auto;
    	max-width:1080px;
    	justify-content:center;
    	padding:0 15px;

    	@include media-breakpoint-up(md) { 
    		flex-wrap:nowrap;
    		height:inherit;
    		justify-content:space-between;
    	}    

    	@include media-breakpoint-up(lg) {
    		max-width:1280px;
    	}
    }

	&__content {
		margin-bottom:25px;
		max-width:760px;
        padding: 0 15px;
        position:relative;
        text-align:center;
        z-index:1;

        @include media-breakpoint-up(md) { 
        	margin-bottom:0;
        	padding: 0 25px;
        }

		h1 {
            font-size:32px;
            font-weight:700;
            line-height:36px;
			color:$white;
			display:block;
			font-family:$font-regular;
			font-weight:bold;
			margin:0 auto 15px;
			max-width:500px;

			@include media-breakpoint-up(md) { 
				color:#2d2e83;
                font-size:46px;
                line-height:52px;
                margin-bottom:20px;
			}
		}

		p {
			color:$white;
            font-size:16px;
            line-height:24px;
			display:block;
			margin:0 auto;
			max-width:340px;

			@include media-breakpoint-up(md) { 
				color:$black;
				font-size:18px;
            	line-height:26px;
				max-width:380px;
			}
		}

		p.small {
			display:none;

			@include media-breakpoint-up(md) { 
				display:block;
			}
		}
	}

	picture img {
        height:100%;
        left: 0;
        object-fit: cover;
        position:absolute;
        top: 0;     
        width:100%; 
	}

	&__actions {
		color:$black;
		display:flex;
		flex-wrap:wrap;
		margin:20px auto 0;
		max-width:260px;
		justify-content:space-between;

		@include media-breakpoint-up(md) { 
			max-width:340px;
		}

		strong {
			color:$white;
			font-size:20px;
			margin-bottom:13px;
			line-height:24px;
			width:100%;

			@include media-breakpoint-up(md) { 
				color:#2d2e83;
				font-size:30px;
				line-height:30px;
				margin-bottom:20px;
			}
		}

		a {
			max-width:124px;
			width:100%;

			@include media-breakpoint-up(md) { 
				max-width:162px;
			}

			img {
				display:block;
				height:auto;
				width:100%;
			}
		}
	}

	&__contest {
		background:rgba(255,255,255,.34);
		border-radius:50%;
		padding:10px;
		position:relative;
		z-index:1;

		@include media-breakpoint-up(md) { 
			align-self:flex-start;
		}

		&__agroup {
			align-items:center;
			background:#bf007b;
			border-radius:50%;
			display:flex;
			height:185px;
			justify-content:center;
			margin:0 auto;
			overflow:hidden;
			padding:0 8px;
			position:relative;
			text-align:center;
			width:185px;

			@include media-breakpoint-up(md) { 
				height:268px;
				width:268px;
			}

			> div {
				padding-bottom:10px;
				@include media-breakpoint-up(md) { 
					padding-bottom:36px;
				}
			}

			img {
				display:block;
				height:auto;
				margin:0 auto 13px;
				width:127px;

				@include media-breakpoint-up(md) { 
					width:152px;
				}
			}

			strong {
				color:$white;
				display:block;
				font-size:17px;
				line-height:20px;
				margin:0 auto 6px;
				max-width:235px;
				text-align:center;

				@include media-breakpoint-up(md) { 
					font-size:24px;
					line-height:30px;
					max-width:235px;
				}
			}

			i {
				color:$white;
				display:block;
				font-size:12px;
				font-weight:bold;
				line-height:21px;
				margin:0 auto 7px;
				text-align:center;

				@include media-breakpoint-up(md) { 
					font-size:16px;
					line-height:21px;
				}
			}
		}

		&__cta {
			bottom:20px;
			left:50%;
			margin-top:0;
			position:absolute;
			transform:translate(-50%,0);
			transition:bottom .2s ease-in-out; 

			-webkit-animation-name: scrollDown;
	        -webkit-animation-duration:.6s;
	        -webkit-animation-iteration-count:2;
	        -webkit-animation-timing-function: ease-in-out;
	        -webkit-animation-delay:1.5s;

			&:hover {
	        	bottom:15px;
	        }   

			img {
				cursor:pointer;
				margin-bottom:0;
				width:25px;

				@include media-breakpoint-up(md) { 
					width:36px;
				}
			}
		}
	}

	p.small {
		color:$white;
        font-size:14px;
        font-style:italic;
        font-weight:bold;
        line-height:20px;
		margin:20px auto 0;	

		@include media-breakpoint-up(md) { 
			color:$black;
		}

		&.mobile {
			bottom: 15px;
			display: block;
		    left: 15px;
		    position: absolute;
		    z-index: 1;

			@include media-breakpoint-up(md) { 
				display:none;
			}
		}

	}
}