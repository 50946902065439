
%font-regular {
    font-weight:400;
}

%font-medium {
    font-weight:500;
}


%font-bold {
    font-weight:700;
}







