// ==========================================================================
// Buttons
// ==========================================================================

// 1.Config



// 2.Base

.btn {
	border-radius: 0;
	text-transform: uppercase;
	line-height:1.1;
	background:#fff;
	border:1px solid #000;
	color:$gray-600;
	@extend %font-regular;
	display: inline-block;
	width:100%;
	padding:18px 15px;
	font-size: 24px;
	outline: none; 
	appearance: none;

	&:hover {
		//border:none;
		color:$gray-600;
		background:darken( #fff, 10% );
	}

	&--small {
		font-size: 20px;
		padding:10px 15px;
	}



	&--primary {
		background:$primary;
		border:1px solid $primary;
		color:#fff;
		&:hover {
			color:#fff;
			background:darken( $primary, 5% );
		}
	}


	&--secondary {
		background:$gray-200;
		border:1px solid $gray-200;

		&:hover {
			background:darken( $gray-200, 10% );
		}
	}

}

form.presSistemaProcesoForm {
	.btn {
		display: none;
	}

	&.selected {
		.btn {
			display: inline-block;
		}
	}
}


body.desktop .btn {
	max-width: 250px;
	font-size: 16px;
	margin:0 auto;
	padding:14px 10px;
}

