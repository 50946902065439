
// ==========================================================================
// PLACEHOLDERS
// ==========================================================================

// Clearfix

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


// Vertical  align centered

%v-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

%v-align-sm {
  @include media-breakpoint-up(sm) { 
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

%v-align-md {
  @include media-breakpoint-up(md) { 
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

%v-align-lg {
  @include media-breakpoint-up(lg) { 
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}


// Horizontal  align centered

%h-align {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}


// Centered

%centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


// Full link

%full-link {
    display:block;
    height:100%;
    width:100%;
    position:absolute;
}


// Reset List

%reset-list {
  padding:0;
  margin:0;

  li {
    list-style:none;
  }
}


// Text ellipsis

%ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


// Background Cover

%bg-cover{
  background-size:cover;
  background-position:center;
}

%svg-cover{
  background-size:auto 100%;
  background-position:center;
  background-repeat:no-repeat;
}


// For pseudo-elements

%pseudos {
  display: block;
  content: ''; 
  position: absolute;
}




