// ==========================================================================
// Landing-app
// ==========================================================================

// 1.Config


// 2.Base
body.landing-app {
	background:#fff!important;
	padding:0!important;
	position: relative;
	height:100vh;
}


.landing-app {
	
	&__content {
		padding-left: 20px;
		padding-top:50px;
		margin:0 auto 0;
		@extend %clearfix;
		position: relative;	

		@include media-breakpoint-up(md) { 
			padding-top: 0;
			width: 920px;
		    top: 50%;
		    transform: translateY(-50%);
		    padding-left: 50px;
		  }

	}

	&__info {
		

		@include media-breakpoint-up(md) { 
		    position: absolute;
		    top: 50%;
		    transform: translateY(-50%);
		    right: 0;
		    width:calc(100% - 380px);
			padding-left:50px;
		  }
		
	}

	&__logo {
		width:260px;
		margin-bottom:40px;

		@include media-breakpoint-up(md) { 
			width:336px;
		}
	}

	&__title {
		font-weight:bold;
		font-size:28px;
		color:#000000;
		padding-right: 40px;

		@include media-breakpoint-up(md) { 
			padding-right: 40px;
			font-size:40px;
		}
	}

	&__text {
		color:#000000;
		font-size:20px;
		line-height: 1.4;
		

		@include media-breakpoint-up(md) { 
			padding-right: 40px;
			font-size:25px;
		}

	}

	&__links {
		@extend %reset-list;
		margin-top: 20px;

		li {
			display:inline-block;
			width: 159px;
			margin-right: 30px;
			margin-bottom:10px;

			img {
				width: 100%;
			}
		}

	}

	&__image {
		width:290px;
		margin-top: 50px;

		@include media-breakpoint-up(md) { 
			float:left;
			width:320px;
			margin-top: 0;
		}

		img {
			width: 100%;
		}
	}
}