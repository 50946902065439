// ==========================================================================
// Sharers
// ==========================================================================


// 1.Config
$colorLink: $black;


// 2.Base

.share {

	&__item {
		font-size:14px;
		color:$colorLink;
		display:inline-block;
		margin:0 15px;
		opacity:1;
		transition:$transition-base;
		vertical-align:middle;

		@include media-breakpoint-up(md) {
			font-size:15px;
			margin:0 10px;
		}

		&:hover {
			opacity:.75;
			text-decoration:none;
		}

		&.share:first-child { margin-left:0; }
		&:last-child { margin-right:0; }

		&.ico-twitter {
			background-image: url(../../svg/twitter.svg);
			background-position:center;
			background-repeat:no-repeat;
			background-size:contain;
			height:16px;
			width:19px;
		}
		&.ico-facebook {
			background-image: url(../../svg/facebook.svg);
			background-position:center;
			background-repeat:no-repeat;
			background-size:contain;
			height:19px;
			width:9px;
		}
		&.ico-mailto {
			background-image: url(../../svg/mail.svg);
			background-position:center;
			background-repeat:no-repeat;
			background-size:contain;
			height:15px;
			width:21px;
		}
	}


	&__item__init {
		font-size:13px;
		font-weight:700;
		margin-left:0;
		
		@include media-breakpoint-up(md) {
			font-size:14px;
		}

		&:hover {
			opacity:1;
		}
	}
}