// ==========================================================================
// Accordeon
// ==========================================================================

// 1.Config


//2. Extends



// 3.Base


.accordeon-add {

    &__link { 
        position: relative;
        display: block;
        padding: 0 30px 0 65px;
        background: #e4d1db;
        @extend %font-regular;
        line-height: 3;
        cursor: pointer;
        margin:0;
        color:#871652;
        font-size:16px;
        line-height: 24px;
        min-height: 60px;
        display: flex;
        align-items: center;
        font-size:16px;
        line-height: 24px;
        transition:$transition-base;
        
         &:before {
            @extend %pseudos;
            left:15px;
            transition:$transition-base;
            font-family:'reveton';
            content: '\e80d';
            font-size: 33px;
            transform-origin:center;
            transition: all .2s;
        }

        &:hover {
             background: darken(#e4d1db,10%);   
             text-decoration:none;
             color:#871652;
        }

    }


    

    

   
}


