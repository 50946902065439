// ==========================================================================
// Accordeon Submenu
// ==========================================================================

// 1.Config


//2. Extends



// 3.Base


.accordeon-submenu {
    @extend %reset-list;

    &__item {
        border-bottom:2px solid #eeeeee;

        &:last-child {
            border:none;
        }

    }


    &__link {
        position: relative;
        display: block;
        padding: 0 15px 0 15px;
        @extend %font-regular;
        line-height: 3;
        cursor: pointer;
        margin:0;
        color:#000;
        font-size:16px;
        line-height: 24px;
        min-height: 95px;
        display: flex;
        align-items: center;
        font-size:16px;
        line-height: 24px; 
        transition:$transition-base;

        &:hover {
            background-color: #ffeff7;
            text-decoration:none;
            color:#000;

        }

    }
   
   
}






