// ==========================================================================
// Login
// ==========================================================================

// 1.Config


// 2.Base

.badgets-forms {
	.col-sm-12 {
		padding:0;
	}
}