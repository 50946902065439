.img-bg-section {
	position:relative;

	&__box {
		background:$white;
		color: #000;
		margin:0 auto 20px;
		max-width: 440px;
		padding: 15px 20px;

		@include media-breakpoint-up(md) { 
			border-radius:6px;
			bottom:25px;
			padding: 20px;
			position:absolute;
			right:25px;
		}

		h2 {
			font-size:24px;
			font-weight:700;
			line-height:30px;
			margin-bottom:7px;

			@include media-breakpoint-up(md) { 
				font-size:24px;
				line-height:30px;
				max-width:380px;
			}
		}

		p {
			font-size:15px;
			line-height:21px;
			margin-bottom:20px;

			@include media-breakpoint-up(md) { 
				font-size:15px;
				line-height:22px;
			}
		}
	}

	img {
		display:block;
		height:auto;
		width:100%;
	}
}