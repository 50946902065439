// ==========================================================================
// Menu Block
// ==========================================================================

// 1.Config


// 2.Base

.menu-block {
	@extend %reset-list;

	&__item {
		margin-bottom:15px;
		text-align:center;

		&:last-child {
			margin-bottom:0;
		}
	}

	&__link {
		display:block;
		margin:0;
		background: #eee;
		transition:$transition-base;
		font-size:21px;
		color:#5c5c5c;
		text-transform: uppercase;
		line-height:1.4;
		padding:20px 45px;

		&:hover {
            background: darken(#eee,10%);   
            text-decoration:none;
            color:#5c5c5c;
        }

        &--bg {
        	background:$primary;
        	color: $white;

        	&:hover {
	            background: darken($primary,10%);   
	            text-decoration:none;
	            color: $white;
	        }
        }
	}

	&__ico {
		display: block;
		margin-bottom:10px;
		font-size:45px;
	}

}