// ==========================================================================
// home
// ==========================================================================

// 1.Config

$lightboxTitleColor:$primary;
$lightboxTitleBg:#EFEFEF;

$lightboxText:$black;


// 2.Base

.m-lightbox {
   width: calc(100% - 60px);
    max-width: 400px;

    &--confirmation {
      transition:$transition-base;
      transition-delay:0.5s!important;
      opacity:0;
    }

   &--brandbook {
      width:1030px;
   }
 
   &__close {
      top: -23px;
      right: 15px;
      z-index:1;
   }

   &__title{

        background:$lightboxTitleBg;
        color:$black;
      padding:10px 15px;
     font-size:16px;
    line-height:21px;
    @extend %font-medium;
        position:relative;

        span {
          font-weight:400;
          color:#9B9B9B;
        }
   }
 
   &__content {
        padding:20px;
   }


   &__text {
        margin-bottom:25px;
        
        p {
            font-size:14px;
            line-height:24px;
            color:$lightboxText;

            margin:0 0 10px;
            &:last-child {
                margin:0;
            }
        }
   }


   &__buttons {
    padding-bottom: 40px;

    .btn {
      margin-bottom:20px;
    }
   }

    &__content-scroll {
       height:325px;
       overflow:hidden;
       position:relative;
       margin-bottom:30px;

       a, a:hover {
        text-decoration:underline;

       }

       p {
            font-size:16px;
            line-height:22px;
            letter-spacing:0.25px;
            color:#4A4A4A;
            font-weight:300;
            margin:0 0 10px;
            &:last-child {
                margin-bottom:25px;
            }

            strong {
              color:#000;
            }
        }

        ol {
          list-style:inherit;
          list-style-type: upper-roman;
            li {

              font-size:16px;
              line-height:22px;
              letter-spacing:0.25px;
              color:#4A4A4A;
              font-weight:300;
              margin:0 0 10px 20px;
               list-style:inherit;
              list-style-type: lower-alpha;
              &:last-child {
                margin-bottom:25px;
              }

            strong {
              color:#000;
            }
            }
        }

        &:after {
          @extend %pseudos;
          height:50px;
          width:100%;
          bottom:0;

          background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

        }

        .jspTrack {
          z-index:1;
        }

   }

   .form-group {
      margin-bottom:40px;

   }
	
}





//CONFIRM

.m-lightbox-overlay {
  display: block;
  visibility: visible;
  position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  @include background-opacity(#4A4A4A, 0.5);
  z-index:999999;

  .m-lightbox {
    @extend %centered;
    background:#fff;
    box-shadow:1px 1px 7px rgba(0, 0, 0, 0.15);
  }
}


//admin form





