.contest-banner {
	height:495px;
	position:relative;

	@include media-breakpoint-up(md) { 
	    align-items: center;
	    display: flex;
		height:420px;
		justify-content: flex-start;
	}

	picture img {
		height:100%;
        left: 0;
        object-fit: cover;
        position:absolute;
        top: 0;   
        width:100%;
	}

	&__logo {
		border:6px solid rgba($white, .72);
		border-radius:50%;
		display:none;
		height:100px;
		margin-bottom:10px;
		overflow:hidden;
		width:100px;

		@include media-breakpoint-up(md) { 
			display:block;
			height:140px;
			margin-bottom:0;
			width:140px;
		}

		&__bg {
			align-items:center;
			background:#bf007b;
			display:flex;
			height:100%;
			justify-content:center;
		}

		span {
			color:$white;
			font-size:15px;
			font-weight:bold;
			line-height:15px;
			text-align:center;

			@include media-breakpoint-up(md) { 
				font-size:24px;
				line-height:24px;
			}
		}
	}

	&__agroup {
		color:$black;
		margin:0 auto;
		max-width:1110px;
		padding:0;
		position:relative;
		width:100%;
		z-index:2;
	}

	&__top {
		align-items:center;
		display:flex;
		flex-direction:row-reverse;
		justify-content:space-between;

		@include media-breakpoint-up(md) { 
			flex-wrap:nowrap;
		}
	}

	&__info {
		max-width:410px;
		padding:20px 15px;

		@include media-breakpoint-up(md) { 
			max-width:540px;
		}

		h3 {
			display:block;
			font-size:24px;
			font-weight:700;
			line-height:30px;

			@include media-breakpoint-up(md) { 
				max-width:calc(100% - 140px);
				padding-right:20px;
			}
		}

		small {
			font-size:13px;
			line-height:22px;
			display:block;
			margin-top:14px;

			a {
				color:$black;
				text-decoration:underline;
				transition:$transition-base;

				&:hover {
					color:#2d2e83;
				}
			}
		}
	}

	&__actions {
		color:$black;
		display:flex;
		flex-wrap:wrap;
		margin:30px 0 0;
		max-width:260px;
		justify-content:space-between;

		@include media-breakpoint-up(md) { 
			max-width:300px;
		}

		strong {
			color:#2d2e83;
			font-size:20px;
			margin-bottom:14px;
			line-height:26px;
			text-align:left;
			width:100%;

			@include media-breakpoint-up(md) { 
				font-size:24px;
				line-height:26px;
			}
		}

		a {
			max-width:124px;
			width:100%;

			@include media-breakpoint-up(md) { 
				max-width:145px;
			}

			img {
				display:block;
				height:auto;
				width:100%;
			}
		}
	}
}