// ==========================================================================
// Login
// ==========================================================================

// 1.Config


// 2.Base

.section-login {

	&__block {
		margin-top: 40px;	


	}

	&__block + &__block  {
		border-top: 1px solid #eee;
		padding-top: 40px;
		margin-top: 40px;
	}

	&__recover {
		text-align: center;
		margin-top: 25px;

		a {
			color: #5c5c5c;

			&:hover {
				color:$primary;
			}
		}
	}


	.col-sm-12 {
		padding: 0;
	}
}


body.desktop {

	.section-login__block {
		max-width: 400px;
		margin:0 auto;

		&--register {
			max-width: 800px;
		}
	}
}