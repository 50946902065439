// ==========================================================================
// Form Uploader
// ==========================================================================


// ==========================================================================
// Add media
// ==========================================================================

// 1.Config

$addmedia-bg: $white;

// 2.Base

.m-add-media__fields + .form-input__label {
  display: none;
}


.m-add-media-buttons {
  position: relative;
  overflow:hidden;
}

.file-upload-input {
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
}

.m-add-media {
  
  @extend %clearfix;


    


 
  @include e('drag') {
    @extend %clearfix;
    height:100%;
    border: 3px dashed #efefef;
    margin-bottom:25px;
    position:absolute;
    width:100%;
    @include background-opacity(#fff,0.95);
      z-index:1;
      opacity:0;
      pointer-events:none;

     


    p {
      margin:0;
      text-align:center;
      @extend %centered;
      //font-family:$second-font-family;
      color:$gray-600;
       font-size:18px;
    }
  }

  &.m-add-media--empty {
    .m-add-media__drag {
      height:150px;
      position:relative;
      opacity:1;
      pointer-events:auto;
    }
  }

   &.m-add-media--dragging {
      .m-add-media__drag {
        opacity:1;
        pointer-events:auto;
      }
    }

  @include e('fields') {
    @extend %clearfix;
    overflow-y:hidden;
    margin-bottom:25px;
    position:relative;
  }

  @include e('cover') {
    max-width:345px;
    width: 100%;
    height:175px;
    background-color:#efefef;
    background-size:cover;
    background-position:center;

    position:relative;

    .m-add-media__actions__delete {
      display: block !important;
      width: 37px;
      height: 37px;
      top: 4px;
      right: 4px; 
      border-radius: 50%;     
      font-size: 25px;
      color: #fff;
      background: $primary;
    }

    @include m('logo') {
      width:160px;
      height:130px;
      position:relative;
      cursor:pointer;

      &:before {
      
        font-size:30px;
        @extend %centered;
        color:$gray-600;
        transition:$transition-base;
      }

      &:hover:before {
        color:$primary;
      }

    }

    a {
      @extend %full-link;
      font-size:65px;
      text-align:center;
    }

    img {
      width:100%;
    }


    &--doc {
      height:90px;
      width:90px;
      background:#fff;

      [class^="file-"]::before, [class*=" file-"]::before  {
        line-height:87px!important;
        color:$primary !important;

      }

      &:hover [class^="file-"]::before, &:hover [class*=" file-"]::before {
        color:$primary;
      }
    }  
  }

  @include e('info') {
    overflow-y:hidden;
    padding-bottom:20px;
  }

  @include e('row') {
    @extend %clearfix;  
    margin-bottom:30px;    
  }

  @include e('actions') {
    margin-top:10px;
    a > .ico {
        margin:0 5px;
      

        &:hover {
          color:$primary;
        }
      }
  }

  @include e('dimensions') {
    font-size:11px;
    color: $secondary;
    font-weight:300;
  }

  @include e('input') {

    .form-group {
      margin:0;

      label {
        text-align:left;
        width:95px;
        padding-right:10px;
      }
    }
  }

  @include e('loader') {
    height:2px;
    width:100%;
    position:absolute;
    left:0;
    bottom:0;
    background:#efefef;
  }

  @include e('loader-bar') {
    height:100%;
    width:0;
    position:absolute;
    left:0;
    bottom:0;
    background:$primary;
    transition:$transition-base;
  }

  .input-message { 
    bottom:-30px;
    right:45px;
  }
 
}

.form-group.m-add-media--bg {
    margin-bottom:0;
    //padding:50px 50px 30px 0;         
    //background:$addmedia-bg;
  }