.new-footer {
	background:$black;
	padding:15px 0;

	@include media-breakpoint-up(md) {
		padding:5px 0;
	}

	.container, .container-fluid {
		padding:0;

		@include media-breakpoint-up(md) {
			align-items:center;
			display:flex;
			flex-direction:row-reverse;
			justify-content:space-between;
		}
	}

	&__menu {
		list-style-type:none;
		margin:0;
		padding:0 15px;;

		@include media-breakpoint-up(md) {
			align-items:center;
			display:flex;
			flex-wrap:nowrap;
			padding-right:15px;
		}

		li {
			font-size:13px;
			display:block;
			margin-bottom:10px;
			text-align:center;

			@include media-breakpoint-up(md) {
				display:flex;
				flex-wrap:nowrap;
				margin:0 15px;
				margin-bottom:0;
			}

			&:last-child {
				margin-bottom:0;
				margin-right:0;
			}	

			a {
				color:$white;
				display:block;
				transition:$transition-base;

				&:hover {
					color:#bf007b;
					text-decoration:none;
				}

				.selected { color:#bf007b; }
			}
		}
	}

	&__legal {
		font-size:13px;
		border-top:2px solid $white;
		color:$white;
		margin-top:15px;
		padding:15px 15px 0;
		text-align:center;

		@include media-breakpoint-up(md) {
			border-top:0;
			margin-top:0;
			padding-left:15px;
			padding-top:0;
			text-align:left;
		}
	}
}