.delay1 {
			animation-delay: 0.3s; 
}
	
.delay2 {
			animation-delay: 1s; 
}
	
.delay3 {  
			animation-delay: 1.5s;
}
	
.delay4 { 

			animation-delay: 2s; 
}

.delay5 {
			animation-delay: 2.5s; 
}	

.delay6 {
			animation-delay: 3s; 
}


