// ==========================================================================
// Login
// ==========================================================================

// 1.Config


// 2.Base

.badgets-home {
	padding:40px 0 ;    
}


body.desktop .badgets-home {
	margin:0 auto;
	max-width: 500px;
}