// ==========================================================================
// Product Form
// ==========================================================================

// 1.Config


// 2.Base

.product-form {
	&__content {
		color:#000;
	}

	&__info {
		@extend %clearfix;
	}
	

	&__image {
		width:140px;
		display:block;
		float: right;
		margin-top:-12px;

	}

	&__title {
		margin:0 0 5px;
		@extend %font-medium;
		text-transform:uppercase;
		font-size:18px;
		line-height: 21px; 
		color:#000;

	}

	&__ref {
		font-size:16px;
		margin:0; 
		color:#000;  	
	}

	&__description{
		font-size:16px;
		line-height: 24px;
		color:#000;
		margin:20px 0 20px;
	}

	&__guarantee {
		margin:0 0 40px;
		font-size:18px;
		line-height: 21px;
		@extend %font-bold;
		position:relative;
		padding:0 0 0 40px;
		color:#000;

		&:before {
			@extend %pseudos;
			width:25px;
			@extend %v-align;
			left:0;
			font-family:'reveton';
            content: '\e810';
            text-align: center;
            font-size:30px;

		}

	}

	&__radio {
		position: relative;
		margin:20px 0 20px 0;

		input {
			opacity: 0;
        	position: absolute; 
        	cursor: pointer;
		}

		label {
			width: 32px;
			height: 32px;
			border:1px solid #5c5c5c;
			display: block;
			border-radius:50%;
			cursor: pointer;
			position:relative;

			&:before {
		        @extend %pseudos;
		        width: 16px;
		        height: 16px;
		        background:#eee;
		        border-radius: 50%;
		        @extend %centered;
		        transition:$transition-base; 

			      
		    }
		}

		input:checked + label:before {
	        background:$primary;
	    }
	}


	.row .col-sm-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
		
		#precioTotal.form-input__input {
				color:#871652;
			}

		
	}
	

}