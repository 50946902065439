// ==========================================================================
// Base
// ==========================================================================

// 1.Config


// 2.Base
body {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @extend %font-regular;
    background-color:#fff; 
    height:100%;
    padding:0;
     position:relative;	

    

    &.desktop {
        background-color: #e6e6e6!important;
        padding-top: 60px;
        
    }

}



.wrapper {
	max-width: 910px;
	background-color:$white;
	margin:0 auto;
	min-height:100%;
	position:relative;
    background-color:#fff;
}


.container {
	max-width: 910px;
    background-color:#fff;


    p {
        color:$black;
    }
	
}

.disabled-click {
    pointer-events: none;
    cursor: default;
    opacity: .6;
}



body.desktop .container {
    padding-left:30px;
    padding-right:30px;
}