// ==========================================================================
// Main
// ==========================================================================

// 1.Config


// 2.Base

.main {
	padding-top: 55px;
	
	&--home {
		padding:0;
	}
}

body.desktop .main {
	padding-top: 0;
	padding-bottom: 50px;
}