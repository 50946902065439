// ==========================================================================
// Form Input
// ==========================================================================

// 1.Config

$inputTextColor: $gray-600;
$inputPaddingV:11px;
$inputPaddingH:3px;
$inputColorFocus: $primary;
$inputTextBorderColor: #000000;
$inputFontSize: 18px;
$inputLabelFontSize: 14px;
$inputTransitionBorder:0.4s;
$inputTransitionLabel:0.3s; 


//2. Extends
%labelFocusStyle {    
    top: -11px;
    font-size: $inputLabelFontSize;
}

%borderFocusStyles {
    width: 100%;
}

// 3.Base
.form-group {
    padding: 15px 0;
    margin: 0;

    &--extra-margin {
      margin-top: 30px;
    }

    &--centered {
      text-align: center;
    }
}


.form-search {
    position:relative;
    text-align: left;

    &__input{
        font-size:$inputFontSize;
        color: $inputTextColor;
        width: 100%;
        box-sizing: border-box;
        border: none;
        border-radius:0;
        padding: $inputPaddingV  50px $inputPaddingV $inputPaddingH;
        border-bottom: 1px solid $inputTextBorderColor;
        background-color: transparent;

        &--important {
          color:$primary;
          @extend %font-bold;
        }

        &:focus {
          outline: none;
        }

        &--textarea {
          height: 140px;
          resize: none;
        }
    }

    &__border{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        transition: $inputTransitionBorder;
        @extend %borderFocusStyles;

        &--textarea {
          bottom:2px;
        }


        &:after {
          pointer-events:none;
          @extend %pseudos;
          font-family:'reveton';
          content: '\e811';
          right:7px;
          margin-top: -42px;
          height: 20px;
          width: 20px;
          opacity: 1;
          font-size:20px;
        }
    }

     &__label{
      position: absolute;
      left: $inputPaddingH;
      width: 100%;
      color: $inputColorFocus;
      @extend %labelFocusStyle;
      transition: $inputTransitionLabel;
      pointer-events:none;
    }


// 4.Focus   

    &__input:focus ~ &__border, &__input.has-content:focus ~ &__border {
        @extend %borderFocusStyles;
        background-color: $inputColorFocus;
    }
    &__input.has-content ~ &__border{
      @extend %borderFocusStyles;
      background-color: $inputTextBorderColor;
    }

 

  


  

}