@charset "UTF-8";

body {
	-webkit-backface-visibility: hidden; // Addresses a small issue in webkit: https://css-tricks.com/almanac/properties/b/backface-visibility/
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both;

	&.hinge {
		        animation-duration: 2s;
	}
}



.animation {
	visibility:hidden;
}