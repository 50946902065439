// ==========================================================================
// alerts
// ==========================================================================

// 1.Config


// 2.Base

.m-alert {
	border:1px solid;

    margin-top: 1rem;
    background-color: #fcf8e3;
    border-color: #faf2cc;
    color: #8a6d3b;
    padding:12px 20px 12px 20px;
    width:100%;
    position:fixed;
    bottom:0;
    left:0;
    z-index:10000;
    opacity: 0.95;
    visibility:hidden;

    &__close {
    	background: none;
    	border: 0 none;
    	cursor: pointer;
    	padding: 0;
    	float:right;
    	margin-left:20px;
    	opacity: 0.4;

    	&:hover {
    		opacity:1;
    	}
    }

    &__text {
        text-align:center;
    }

    &--success {
    	background-color: #dff0d8;
    	border-color: #d0e9c6;
    	color: #3c763d;
    }

    &--info {
    	 background-color: #d9edf7;
    	 border-color: #bcdff1;
         color: #31708f;
    }
	

	&--warning {
    	background-color: #fcf8e3;
    	border-color: #faf2cc;
    	color: #8a6d3b;
    }
	

	&--danger {
    	background-color: #f2dede;
	    border-color: #ebcccc;
	    color: $red;
    }
	
}