// ==========================================================================
// Global Config
// ==========================================================================


$version-statics: "1.0";
$url-statics: "../";
$image-path: "images";

/************************************/

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eeeeee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #5c5c5c !default;
$gray-700: #4A4A4A !default;
$gray-800: #343a40 !default;
$gray-900: #000 !default;
$black:    #000 !default;

$blue:    #001489 !default;
$indigo:  #6610f2 !default;
$pink:    #e83e8c !default;
$red:     #ff5656 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
 
$purple: #871652; 


/************************************/

$primary:       $purple !default;
$secondary:     $gray-600!default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default; 

$body-color:    $primary;
$body-bg:       $gray-200;

/************************************/


$font-regular:        'Roboto';

$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-base:            $font-regular;



/************************************/

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;

/************************************/

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1190px,
  xl: 1520px
) !default;


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1440px
) !default;

/************************************/

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;

/************************************/

$z-indexes: (
  'header' : 101,
  'navbar' : 101,
  'footer' : 101
);

/************************************/

$headerHeight: 50px;
$headerHeightSm: 90px;



