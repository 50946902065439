.section-list {
	padding:30px 0;

	@include media-breakpoint-up(md) { 
		padding:20px 0 0;
	}

	@include media-breakpoint-up(lg) { 
		padding:80px 0 0;
	}

	.container {
		max-width:1120px;
		width:100%;
	}

	&__columns {
		align-items:center;
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;

		@include media-breakpoint-up(md) { 
			flex-wrap:wrap;
		}
	}

	&__columns--reverse {
		flex-direction:row-reverse;
	}

	&__column {
		width:100%;

		@include media-breakpoint-up(md) { 
			width:50%;
		}

		&--flex-end {
			@include media-breakpoint-up(md) { 
				align-self:flex-end;
			}
		}
	}

	&__small-content {
		margin:0 auto;
		max-width:392px;
		text-align:center;

		h2 {
			color:$black;
			font-size:26px;
			font-weight:700;
			line-height:32px;
			margin:0 auto 20px;
			max-width:349px;

			@include media-breakpoint-up(md) { 
                font-size:36px;
                line-height:42px;
                margin-bottom:0;
			}
		}

		img {
			border-bottom:1px solid $black;
			display:block;
			height:auto;
			margin:0 auto 40px;
			width:298px;

			@include media-breakpoint-up(md) { 
				border:0;
				margin-bottom:0;
				width:392px;
			}
		}
	}
}