@keyframes fadeInLeftBig {
	0% {
		opacity: 0;
		transform: translateX(-2000px);
	}
	
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}



.animated.fadeInLeftBig {

	animation-name: fadeInLeftBig;
}