// ==========================================================================
// Footer
// ==========================================================================

// 1.Config




// 2.Base

.footer {
	display: none;
	height:35px;
	
	width:100%;	
	display:block;
	text-align:center;
	
	display: none;
	
	.container {
		background:#5c5c5c;
	}

	&__menu {
		@extend %reset-list;
		margin:	0;
		padding: 0;
		text-align: center;

		li {
			display: inline-block;
			@extend %font-medium;
			line-height: 35px;
			margin:0 12px 0 0;

			&:after {
				content: '-';
				color:#fff;
				padding-left:10px;
			}

			a {
				color:#fff;
				font-size:15px;
			}

			&:last-child {
				margin: 0;
					&:after {
					content: none;
				}
			}
		}
	}
}

body.desktop  .footer{
	display:block;
}