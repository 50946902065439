.avantages-ico-list {
	padding:0;
	
	li {
		display:flex;
		flex-wrap:nowrap;
		margin-bottom:24px;

		@include media-breakpoint-up(md) {
			margin-bottom:46px;
		}

	}

	&__ico {
		align-items:center;
		background:#01809b;
		border-radius:50%;
		display:flex;
		height:60px;
		justify-content:center;
		width:60px;

		@include media-breakpoint-up(md) { 
			height:100px;
			width:100px;
		}

		&.yellow { background:#fba44b; }
		&.purple { background:#b12e70; }
		&.green { background:#2bb58c; }

		img {
			width:30px;

			@include media-breakpoint-up(md) { 
				width:48px;
			}
		}
	}

	&__info {
		margin-top:15px;
		padding-left:20px;
		width:calc(100% - 70px);

		@include media-breakpoint-up(md) {
			padding-left:40px;
			width:calc(100% - 110px);
		}

		h3 {
			color:$black;
			font-size:16px;
			font-weight:bold;
			line-height:22px;
			margin-bottom:8px;

			@include media-breakpoint-up(md) {
				font-size:18px;
			}
		}

		p {
			font-size:14px;
			line-height:21px;
			margin:0 auto;

			@include media-breakpoint-up(md) {
				font-size:15px;
				line-height:22px;
			}
		}
	}
}