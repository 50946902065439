// ==========================================================================
// Accordeon
// ==========================================================================

// 1.Config


//2. Extends



// 3.Base


.accordeon {

    &__item {
        position: relative;
        margin-bottom: 4px;
        width: 100%;
        overflow: hidden;
    }

    &__input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }


    &__label {
        position: relative;
        display: block;
        padding: 0 0 0 15px;
        background: #eee;
        @extend %font-regular;
        line-height: 3;
        cursor: pointer;
        margin:0;
        color:#000;
        font-size:16px;
        line-height: 24px;
        min-height: 100px;
        display: flex;
        align-items: center;
        font-size:16px;
        line-height: 24px;
        transition:$transition-base;


        &:after {
            @extend %pseudos;
            font-family:'reveton';
            content: '\e80a';
            font-size: 30px;
            transform-origin:center;
            right:20px;
            transition: all .2s;
            color:$primary;
            text-align: center
        }
        

        &:hover {
             background: darken(#eee,10%); 
             text-decoration: none;
             color:#000;

        }


        a {
            text-decoration: none;
             color:#000;

        }

    }

    &__link {
        position:relative;
        top:0;
        min-height:100px;
        padding-right:70px;
        width:calc(100% + 70px);
        z-index:1;
        display: flex;
        align-items: center;
       //justify-content: center;
    }

    &__content {
        max-height: 0;
        overflow: hidden;
        background: #fff;
        transition: max-height .2s;
    }

    /* :checked */
    &__input:checked ~ &__content {
        max-height: 100vh;
    }
    /* Icon */

     &__input:checked  + label:after  {
        transform: rotate(90deg);
     }



    


    
    // 4. Selector Chekbox

    &__label--selector-check {
        padding-left:65px;

        &:before {
            @extend %pseudos;
            width:32px;
            height: 32px;
            background:$white;
            left:15px;
            border:8px solid #fff;
            transition:$transition-base;
        }

         &:after {
            content:none;
        }

        
    }

    &__input:checked  + &__label--selector-check:before  {
        background:$primary;
    }

    

    // 4. Selector Radio

    &__label--selector-radio {
        padding-left:65px;


        &:before {
            @extend %pseudos;
            width:32px;
            height: 32px;
            background:$white;
            left:15px;
            border:8px solid #fff;
            transition:$transition-base;
            border-radius:50%;
        }

        &:after {
            content:none;
        }

    }

    &__input:checked  + &__label--selector-radio:before  {
        background:$primary;
    }


     // 4. With arrow

    &__label--arrow {
        &:after {
            content: '\e801';
            opacity:0;
        }
    }

    &__input:checked  + &__label--arrow:after  {
        transform:none;
        opacity:1;
    }

   
}


