// ==========================================================================
// Title Block
// ==========================================================================

// 1.Config


// 2.Base

.info-modal {
	&__ico {
		color:$primary;
		text-align: center;
		font-size:45px;
		margin-bottom: 30px;
	}

	&__title {
		margin:0 0 20px;;
		@extend %font-bold;
		font-size:16px;
		line-height: 24px;
	}

	p {
		font-size:16px;
		line-height: 24px;
		color:#000;
	}

}
