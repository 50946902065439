// ==========================================================================
// Pagination
// ==========================================================================

// 1.Config


// 2.Base

.pagination {
	@extend %reset-list;
	@extend %clearfix;
	margin:20px 0;
	text-align: center;

	li {
		display: inline-block;
		color:$primary;
		margin:0 5px;

		a {
			display:block;
			margin:0;
			transition:$transition-base;
			font-size:16px;
			color:#bbbbbb;
			line-height:1.4;
			padding:5px 0;


			&:hover {
	            color: darken(#bbbbbb,10%);   
	            text-decoration:none;
	        }
		}
	}

	

}