// ==========================================================================
// Header
// ==========================================================================

// 1.Config

// 2.Base

.header {
    position:fixed;
    top:0;
    left:0;
    width: 100%;
    //padding:10px 0;
    z-index:10;
      
    display: none;
    
    .container {
        @extend %clearfix;
        height: 60px;
        background-image: url(../../svg/deco_header.svg);
        background-size: auto 100%;
        background-position: right 0;
        background-repeat: no-repeat;
    }

    &__logo {
        float: left;
        @extend %v-align;
        height: 40px;

        img {
            height: 100%;
        }
    }

    &__menu {
        @extend %v-align;
        float: right;
        @extend %reset-list;
        text-align: right;

        li {
            display:inline;
            font-size:25px;
            margin-left: 20px;

            span {
                font-size: 14px;
                color: #000;
            }

        }
    }


    

}

body.desktop  .header{
        display: block;
    }


