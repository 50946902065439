// BEM selectors

@mixin e($element) {
	&__#{$element} {
    @content;
  }
}

@mixin m($modifier) {
  &--#{$modifier} {
    @content;
  }
}
