// ==========================================================================
// Title Block
// ==========================================================================

// 1.Config


// 2.Base

.title-block {
	text-align:left;

	&__name {
		margin:0;
		font-size:16px;
		line-height:21px;
		padding:15px 0;
		@extend %font-regular;
		text-transform:uppercase;
		color:#000;
		
	}

}

.title-block-bg {
	background:$primary;
	text-align:left;
	padding:10px 15px;
	margin:0 -15px 10px;

	&__name {
		margin:0;
		font-size:16px;
		line-height:21px;
		@extend %font-medium;
		color:#fff;

		a {
			color: #fff;
			font-size:20px;

			&:hover {
				color: #efefef;
			}
		}

		&--center {
			text-align: center;
		}

		&--uppercase {
			text-transform:uppercase;
		}
	}


	&--grey {
		background:$gray-200;
		margin-top: 30px;

		.title-block-bg__name {
			color:$black;
		}
	}

}

body.desktop .title-block-bg {
	margin:0 -30px 10px;
	padding:20px 30px;
}


.title-block-ico {
	text-align:center;
	padding-top:30px;
	margin-bottom: 30px;

	&__ico {
		width:105px;
		height:105px;
		border-radius:50%;
		background:$primary;
		display:block;
		margin:0 auto;
		position:relative;

		i {
			color:#fff;
			font-size:40px;
			@extend %centered;
		}
	}

	&__name {
		margin:20px 0 0;
		@extend %font-medium;
		color:$primary;
		font-size:21px;
		line-height:25px;

	}

}