@import 'https://fonts.googleapis.com/css?family=Roboto:400,500,700';

@font-face {
  font-family: 'reveton';
  src: url('../../fonts/reveton.eot?91752466');
  src: url('../../fonts/reveton.eot?91752466#iefix') format('embedded-opentype'),
       url('../../fonts/reveton.woff2?91752466') format('woff2'),
       url('../../fonts/reveton.woff?91752466') format('woff'),
       url('../../fonts/reveton.ttf?91752466') format('truetype'),
       url('../../fonts/reveton.svg?91752466#reveton') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'reveton';
    src: url('../font/reveton.svg?91752466#reveton') format('svg');
  }
}
*/
 
 [class^="ico-"]:before, [class*=" ico-"]:before {
  font-family: "reveton";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


.ico-arrow-left:before { content: '\e800'; } /* '' */
.ico-arrow-right:before { content: '\e801'; } /* '' */
.ico-budget:before { content: '\e802'; } /* '' */
.ico-close:before { content: '\e803'; } /* '' */
.ico-edit:before { content: '\e804'; } /* '' */
.ico-check:before { content: '\e805'; } /* '' */
.ico-file:before { content: '\e806'; } /* '' */
.ico-home:before { content: '\e807'; } /* '' */
.ico-info:before { content: '\e808'; } /* '' */
.ico-list:before { content: '\e809'; } /* '' */
.ico-next:before { content: '\e80a'; } /* '' */
.ico-out:before { content: '\e80b'; } /* '' */
.ico-expand:before { content: '\e80c'; } /* '' */
.ico-plus:before { content: '\e80d'; } /* '' */
.ico-systems:before { content: '\e80e'; } /* '' */
.ico-user:before { content: '\e80f'; } /* '' */
.ico-warranty:before { content: '\e810'; } /* '' */
.ico-search:before { content: '\e811'; } /* '' */
.ico-photo-1:before { content: '\e812'; } /* '' */
.ico-up:before { content: '\e813'; } /* '' */
.ico-down:before { content: '\e814'; } /* '' */
.ico-prev:before { content: '\e815'; } /* '' */
.ico-trash:before { content: '\e816'; } /* '' */
 

