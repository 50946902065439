.video-section {
	position:relative;

	&__player {
		align-items:center;
		background:rgba(0,0,0,0);
		display:flex;
		height:100%;
		left:0;
		justify-content:center;
		position:absolute;
		top:0;
		transition:$transition-base;
		width:100%;

		&:hover {
			background:rgba(0,0,0,.2);

			span { opacity: .85; }
		}

		&:focus {
			outline:none;
		}

		span {
			background: url(../../images/player.png) center no-repeat;
			background-size:contain;
			height:60px;
			transition:$transition-base;
			width:60px;

			@include media-breakpoint-up(md) { 
				height:144px;
				width:144px;
			}
		}
	}

	img {
		display:block;
		height:auto;
		width:100%;
	}
}