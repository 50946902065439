// ==========================================================================
// Content
// ==========================================================================

// 1.Config


// 2.Base
.advanced-search {
	display: none;
	margin-bottom: 15px;

	.form-group {
		@extend %clearfix;
		padding:5px 0;
	}

	&__label {
		font-size:16px;
		width: 50px;
		color:#5c5c5c;
		float: left;

	}

	&__month {
		float: left;
		width:calc(53%);
		padding:0 0 0 10px;
	}

	&__year {
		float: left;
		width:calc(47% - 50px);
		padding:0 0 0 10px;

	}



}


.advanced-search-link {
	font-size:14px;
	color:#5c5c5c;
	text-decoration:underline;
	display: inline-block;
	float: right;
	margin:10px 0 0;

}