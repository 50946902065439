// ==========================================================================
// Menu Block
// ==========================================================================

// 1.Config


// 2.Base

.navigation {
	@extend %reset-list;
	@extend %clearfix;
	margin-top: 10px;
	margin-bottom:30px;
	&__item {
		float:left;
		display:inline-block;

		&:last-child {
			float:right;
		}
	}

	&__link {
		display:block;
		margin:0;
		transition:$transition-base;
		font-size:16px;
		color:#bbbbbb;
		line-height:1.4;
		padding:5px 0;

		&:hover {
            color: darken(#bbbbbb,10%);   
            text-decoration:none;
        }
	}

	/*&__ico {
		font-size:25px;
		display: inline;
	}*/

}