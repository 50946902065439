.testimonials {
	color:$black;
	padding:25px 0;
	text-align:center;

	@include media-breakpoint-up(md) { 
		padding:40px 0;
	}

	&__content {
		h3 {
			font-size::20px;
			font-weight:700;
			line-height:52px;
			margin:0 auto 15px;

			@include media-breakpoint-up(md) { 
				margin-bottom:45px;
			}
		}
	}

	&__item {
		margin:0 auto;
		max-width:329px;
		padding:0 10px;		

		strong {
			display:block;
			font-size:14px;
			font-weight:700;
			line-height:20px;
			margin-bottom:5px;

			@include media-breakpoint-up(md) { 
				font-size:15px;
			}
		}

		p {
			font-size:14px;
			line-height:21px;

			@include media-breakpoint-up(md) { 
				font-size:15px;
				line-height:22px;
			}
		}
	}

	.slide {
		margin:0 auto;
		max-width:1200px;
		padding:0 30px;
		position:relative;
	}
}