// ==========================================================================
// Navbar
// ==========================================================================

// 1.Config


// 2.Base
.submenu{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:55px;
	background-color: #e6e5e7;
	z-index: 100;

	&__back {
		display: block;
		width: 55px;
		height: 55px;
		background:$gray-200;
		position:absolute;
		top: 0;
		left: 0;
		text-align: center;
		line-height: 55px;
		color:$primary;
		font-size: 30px;
	}

	ul {
		padding-left: 35px;
		@extend %reset-list;

		li {
			display:inline-block;
			font-size: 0;	
			margin:0 15px 0;


			a{
				color:#5c5c5c;
				line-height: 55px;
				font-size: 14px;

				i {
					font-size: 16px;
				}

				&:hover {
					text-decoration: none;
					color:$primary;
				}
			}

			&:last-child {
				margin: 0;
			}
		}
	}

}

body.desktop .submenu {
	display: none;
}