// ==========================================================================
// PDF
// ==========================================================================

// 1.Config
/** PDF start **/
 @font-face {
    font-family:"Roboto-Regular";            
    src:local("Roboto-Regular"), url("../fonts/Roboto-Regular-webfont.ttf") format("truetype");
    font-weight:normal;
    font-style:normal;
}

@font-face {
    font-family:"Roboto-Bold";            
    src:local("Roboto-Bold"), url("../fonts/Roboto-Bold-webfont.ttf") format("truetype");
    font-weight:normal;
    font-style:normal;
}

body {
	background: #FFF !important;
}
// 2.Base
.pdf {
	width:1400px;
	margin:0 auto;
	font-family:"Roboto-Regular";  

	p {
		color:#000;
	}

	strong {
		font-weight:400;
		font-family:"Roboto-Bold";  
	}

	&__col-left {
		width:990px;
		float:left;
	}

	&__col-right {
		overflow-y:hidden;
	
	}


	&__image {
		width:100%;
	}

	&__logo {
		max-height:200px;
		max-width:390px;
		margin-left:50px;
	}

	&__block{
		
		  &:after {
		    content: "";
		    display: table;
		    clear: both;
		  }
		margin-bottom:50px;
		
		p {
			//font-size:14px;
			line-height:18px;
		}

		&--top { 
			margin-bottom:100px;
		}

		&--data {
			margin-bottom:200px;	
		}
	}

	&__info {
		text-align:right;
		p {
			line-height:16px;
		}
		
	}

	&__date {
		margin-bottom:10px;
	}

	&__num {
		font-weight:400;
		font-family:"Roboto-Bold";   
		text-transform:uppercase;
		border-bottom:2px solid #000;
		padding:0 0 10px;
		font-size:21px;
		margin-bottom:30px;
	}

	&__title {
		font-weight:400;
			font-family:"Roboto-Bold";   
		font-size:18px;
		color:#000;
		margin:20px 0;
	}

	&__adress {
		margin-top:30px;

		span {
			color:#EB9810;
			font-weight:400;
			font-family:"Roboto-Bold";   
		}
	}

	&__title-content {
		font-size:24px;
		line-height:28px;
		display:inline-block;
		padding:0 0 15px;
		border-bottom:2px solid #000;
		color:#000;
		margin:0 0 30px;
		font-weight:400;
		font-family:"Roboto-Bold";  

	

		&--uppercase {
			text-transform:uppercase;
		}

	}

	&__title-block {
		color:#EB9810;
		font-size:22px;
		font-weight:400;
		display:inline-block;
		font-family:"Roboto-Bold";   
		margin:0 0 20px;
	}

	&__subtitle {
		color:#EB9810;
		font-size:21px;

		font-weight:400;
		font-family:"Roboto-Bold";   
		background-color:#fbeacf;
		padding:0 15px 5px;
		margin:40px 0 30px;
		display:block;
		max-width:1020px;
		width:100%;
	}

	&__item {
		margin-bottom:30px;
	}

	&__item-title {
		margin:0;
		text-transform:uppercase;
		font-weight:400;
		font-family:"Roboto-Bold";   
		font-size:14px;
		line-height:16px;
		display:block;
		max-width:1020px;
		width:100%;
	}

	&__item-description {
		margin:0 0 15px;
		font-size:14px;
		line-height:16px;
		display:block;
		max-width:1020px;
		width:100%;
	}

	&__item-images {
		
		  padding:0;
		  margin:0;

		  li {
		    list-style:none;
		  }
		
		  &:after {
		    content: "";
		    display: table;
		    clear: both;
		  }
		margin-top:35px;
		margin-bottom:20px;

		li {
			float:left;
			width:650px;
			padding:0 10px 0 0;

		}

		li + li {
			padding:0 0 0 10px;
		}


	}

	&__product {
		margin-top:15px;
		
		  &:after {
		    content: "";
		    display: table;
		    clear: both;
		  }
	}

	&__product-image {
		float:left;
		width:230px;
	}

	&__product-info {
		float:left;
		width:630px;
		color:#ccc;
		padding:20px 0 0 20px;
		font-size:14px;

		span {
			display:block;
			color:#000;
			font-size:14px;
			font-weight:400;
			font-family:"Roboto-Bold";   
			text-transform:uppercase;
		}
	}

	&__table-title {
		color:#EB9810;
		font-size:24px;
		font-weight:400;
		font-family:"Roboto-Bold";   
		margin:0 0 20px;
	}

	&__text {
		display:block;


		p {
			font-size:14px;
			line-height:20px;
		}
	}
}

.pdf-table {
	margin-bottom:40px;
	width:1400px;
	font-family:"Roboto-Regular";  

	&__row {
		
		  &:after {
		    content: "";
		    display: table;
		    clear: both;
		  }
		background:#eeee;

		&:first-child  {
			background:none;
		}

	
	}

	&__col {
		float:left;
		width:205px;
		font-size:12px;
		border-bottom:3px solid #fff;
		border-left:3px solid #fff;
		text-align:right;
		color:#000;
		padding:5px 15px 10px;

	

		&--medium {
			width:650px;
			text-align:left;

		}

		&--big {
			width:80%;
			text-align:left;
		}

		&--subtotal {
			margin-top:3px;
			border:3px solid #EB9810;
			float:right;
			text-align:right;
		}
	}
}

.pdf-table--total {
	margin-top:60px;
	background:#fff;
}

.pdf-table__row--white {
		background:#fff;
}

.pdf-table__row--total {
		background:#fff;

	.pdf-table__col {
		background-color:#EB9810;
		font-weight:400;
		font-family:"Roboto-Bold";   
		text-align:right;

		&:first-child {
			margin-left:920px;
		}
	}
}
/** PDF end **/