// ==========================================================================
// Form Acceptance
// ==========================================================================

// 1.Config

$formAcceptanceSize: 34px;
$formAcceptanceBg:$gray-200;
$formAcceptanceBgChecked:$primary;
$formAcceptanceLabelSize: 16px;
$formAcceptanceIcoSize: 14px;

//2. Extends



// 3.Base



.form-acceptance {
    position:relative;

    &__checkbox {
        opacity: 0;
        position: absolute; 
        cursor: pointer;
    } 

    &__label {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        margin:0 0 10px;
        position: relative;
        @extend %font-regular;
        padding-left:50px;
        color:$secondary;
        font-size:$formAcceptanceLabelSize;

        a {
            text-decoration:underline;
            color:$secondary;
            @extend %font-bold;

            &:hover {
           
            }
        }
    }
 
    &__checkbox + &__label:before {
        @extend %pseudos;

        font-family:"reveton";
          content: '\e805';
        left:0;
        @extend %v-align;
        font-size: $formAcceptanceIcoSize;
        background:$formAcceptanceBg;
        color:$formAcceptanceBg;
        border-radius:0;
        line-height:$formAcceptanceSize;
        width:$formAcceptanceSize;
        height: $formAcceptanceSize;
        text-align: center;
        transition:$transition-base;   
    }

    &__checkbox:checked + &__label:before {
        color:$formAcceptanceBgChecked!important;
        background:$formAcceptanceBg!important;
    }



    &.form-input--error {
        .form-acceptance__checkbox +  .form-acceptance__label:before {
            background:$red;
            color:$red;
        }
    }

}