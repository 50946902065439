// ==========================================================================
// Promos
// ==========================================================================

// 1.Config


// 2.Base
.promos {
	&__title {
		text-align: center; 
		font-size:15px;
		margin:0;
		font-weight:normal;
		color:#000;
		padding:10px 0;

		span {
			font-weight:bold;

		}
	}

	&__content {

	}

	&__footer {
		text-align:center;
		padding-top: 20px;

		a {
			text-decoration: underline;
			font-weight:bold;
			color:#000;
			font-size:14px;
		}
	}
}



.promo-box {
	$block: '.promo-box';
	padding:0 30px 40px;

	&__content {
		text-align:center;
		max-width:400px;
		margin:0 auto;
		color: #000;
	}

	&__ico {
		width: 120px;
		margin:0 auto 10px;
		position:relative;

		.circliful {
			.timer {
				display:none;
			}
		}


		img {
			width:51px;
			position:absolute;
			@extend %centered;
		}
	}

	&__title {
		text-transform:uppercase;
		font-size:13px;
		font-weight:bold;
		margin-bottom: 18px;
		line-height:1.4;
		margin-top: -20px;
	}

	&__finish {
		text-transform:uppercase;
		font-size:24px;
		font-weight:bold;
		color:#7d7d7d;
		margin-bottom: 18px;
		line-height:1.4;
		margin-top: -20px;
	}

	&__subtitle {
		font-size:15px;
		font-weight:bold;
		margin-bottom:10px;
		line-height:1.4;
	}

	&__text {
		font-size:13px;
		line-height:1.4;
	}

	&__note {
		margin-top: 5px;
		font-style: italic;
		font-size:11px;
		line-height:1.4;
	}



	&--purple {
		background-color:#dab5cb;

		#{$block}__title {
			color:#840952;
		}
		.circle {
			stroke:#840952!important;
		}

		.border {
			stroke:#dab5cb!important;
		}
		
	}

	&--orange {
		background-color:#fbe2c4;

		#{$block}__title {
			color:#e6800a;
		}

		.circle {
			stroke:#e6800a!important;
		}

		.border {
			stroke:#fbe2c4!important;
		}
	}


	&--blue {
		background-color:#ccdfe6;

		#{$block}__title {
			color:#34819b;
		}

		.circle {
			stroke:#34819b!important;
		}

		.border {
			stroke:#ccdfe6!important;
		}
		
	}

	&--finish {
		background-color:#d8d8d8;

		#{$block}__title {
			color:#7d7d7d;
		}

		.circle {
			stroke:#7d7d7d!important;
		}

		.border {
			stroke:#d8d8d8!important;
		}
		
	}
}